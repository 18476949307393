.rounded-block-card {
  @apply rounded-lg;
}

@screen md {
  .rounded-block-card {
    @apply rounded-xl;
  }
}

.rounded-tl-block-card {
  @apply rounded-tl-lg;
}

@screen md {
  .rounded-tl-block-card {
    @apply rounded-tl-xl;
  }
}

.rounded-tr-block-card {
  @apply rounded-tr-lg;
}

@screen md {
  .rounded-tr-block-card {
    @apply rounded-tr-xl;
  }
}

.rounded-inline-card {
  @apply rounded-md;
}

@screen md {
  .rounded-inline-card  {
    @apply rounded-lg;
  }
}

.rounded-t-inline-card {
  @apply rounded-t-md;
}

@screen md {
  .rounded-t-inline-card  {
    @apply rounded-t-lg;
  }
}

.rounded-b-inline-card {
  @apply rounded-b-md;
}

@screen md {
  .rounded-b-inline-card  {
    @apply rounded-b-lg;
  }
}
