.form-select__indicator-separator,
.form-select-with-error__indicator-separator {
  display: none;
}

.form-select__placeholder,
.form-select-with-error__placeholder {
  @apply !text-midnight;
}

.form-select__placeholder--is-disabled,
.form-select-with-error__placeholder--is-disabled {
  @apply !text-disabled;
}

.form-select__control,
.form-select-with-error__control {
  @apply !text-base !rounded-lg;
}

.form-select__control {
  @apply !border-twilight;
}

.form-select-with-error__control {
  @apply !border-error-shade;
}

.form-select__control--is-disabled,
.form-select-with-error__control--is-disabled {
  @apply !bg-lake-fog !border-disabled;
}

.form-select__value-container,
.form-select-with-error__value-container {
  @apply !p-3;
}


.form-select__control--is-focused,
.form-select-with-error__control--is-focused  {
  @apply !border-abyss !shadow-none;
}
