:root {
  --main-navigation-height-mobile: 56px;
  --main-navigation-height: 64px;
  --main-navigation-child-display: 'block';
  --navigation-scrolltop-offset-mobile: 115px;
  --navigation-scrolltop-offset: 140px;
}

.main-navigation-hidden {
  --main-navigation-height-mobile: 0px;
  --main-navigation-height: 0px;
  --main-navigation-child-display: 'none';
}

.main-navigation-hidden .placeholder-menu-item {
  margin-bottom: 0;
}

@media screen and (max-width: 767px){
  body.disable-body-scroll {
    overflow-y: hidden;
  }
}

.main-navigation-child {
  display: var(--main-navigation-child-display);
}
