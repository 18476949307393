:root {
  --space-xs-breakpoint-xs: 24px;
  --space-xs-breakpoint-sm: 40px;
  --space-xs-breakpoint-md: 40px;

  --space-sm-breakpoint-xs: 40px;
  --space-sm-breakpoint-sm: 60px;
  --space-sm-breakpoint-md: 60px;

  --space-md-breakpoint-xs: 80px;
  --space-md-breakpoint-sm: 100px;
  --space-md-breakpoint-md: 120px;

  /* Breakpoints for new Fishingwaters, should replace md at some point */
  --space-fw-breakpoint-xs: 48px;
  --space-fw-breakpoint-sm: 100px;
  --space-fw-breakpoint-md: 120px;

  --space-lg-breakpoint-xs: 120px;
  --space-lg-breakpoint-sm: 150px;
  --space-lg-breakpoint-md: 180px;

  --space-xl-breakpoint-xs: 160px;
  --space-xl-breakpoint-sm: 200px;
  --space-xl-breakpoint-md: 240px;
}

.mb-xs {
  margin-bottom: var(--space-xs-breakpoint-xs);
}

@screen md {
  .mb-xs {
    margin-bottom: var(--space-xs-breakpoint-md);
  }
}

.mb-sm {
  margin-bottom: var(--space-sm-breakpoint-xs);
}

@screen sm {
  .mb-sm {
    margin-bottom: var(--space-sm-breakpoint-md);
  }
}

.mb-md {
  margin-bottom: var(--space-md-breakpoint-xs);
}

.mb-fw {
  margin-bottom: var(--space-fw-breakpoint-xs);
}

@screen sm {
  .mb-md {
    margin-bottom: var(--space-md-breakpoint-sm);
  }
}

@screen sm {
  .mb-fw {
    margin-bottom: var(--space-fw-breakpoint-sm);
  }
}

@screen md {
  .mb-md {
    margin-bottom: var(--space-md-breakpoint-md);
  }
}

@screen md {
  .mb-fw {
    margin-bottom: var(--space-fw-breakpoint-md);
  }
}


.mb-lg {
  margin-bottom: var(--space-lg-breakpoint-xs);
}

@screen sm {
  .mb-lg {
    margin-bottom: var(--space-lg-breakpoint-sm);
  }
}

@screen md {
  .mb-lg {
    margin-bottom: var(--space-lg-breakpoint-md);
  }
}

.mb-xl {
  margin-bottom: var(--space-xl-breakpoint-xs);
}

@screen sm {
  .mb-xl {
    margin-bottom: var(--space-xl-breakpoint-sm);
  }
}

@screen md {
  .mb-xl {
    margin-bottom: var(--space-xl-breakpoint-md);
  }
}

.mt-xs {
  margin-top: var(--space-xs-breakpoint-xs);
}

@screen sm {
  .mt-xs {
    margin-top: var(--space-xs-breakpoint-sm);
  }
}

@screen md {
  .mt-xs {
    margin-top: var(--space-xs-breakpoint-md);
  }
}

.mt-sm {
  margin-top: var(--space-sm-breakpoint-xs);
}

@screen sm {
  .mt-sm {
    margin-top: var(--space-sm-breakpoint-sm);
  }
}

@screen md {
  .mt-sm {
    margin-top: var(--space-sm-breakpoint-md);
  }
}

.mt-md {
  margin-top: var(--space-md-breakpoint-xs);
}

.mt-fw {
  margin-top: var(--space-fw-breakpoint-xs);
}

@screen sm {
  .mt-md {
    margin-top: var(--space-md-breakpoint-sm);
  }
}

@screen sm {
  .mt-fw {
    margin-top: var(--space-fw-breakpoint-sm);
  }
}

@screen md {
  .mt-md {
    margin-top: var(--space-md-breakpoint-md);
  }
}

@screen md {
  .mt-fw {
    margin-top: var(--space-fw-breakpoint-md);
  }
}


.mt-lg {
  margin-top: var(--space-lg-breakpoint-xs);
}

@screen sm {
  .mt-lg {
    margin-top: var(--space-lg-breakpoint-sm);
  }
}

@screen md {
  .mt-lg {
    margin-top: var(--space-lg-breakpoint-md);
  }
}

.mt-xl {
  margin-top: var(--space-xl-breakpoint-xs);
}

@screen sm {
  .mt-xl {
    margin-top: var(--space-xl-breakpoint-sm);
  }
}

@screen md {
  .mt-xl {
    margin-top: var(--space-xl-breakpoint-md);
  }
}

.my-xs {
  margin-bottom: var(--space-xs-breakpoint-xs);
  margin-top: var(--space-xs-breakpoint-xs);
}

@screen sm {
  .my-xs {
    margin-bottom: var(--space-xs-breakpoint-sm);
    margin-top: var(--space-xs-breakpoint-sm);
  }
}

@screen md {
  .my-xs {
    margin-bottom: var(--space-xs-breakpoint-md);
    margin-top: var(--space-xs-breakpoint-md);
  }
}

.my-sm {
  margin-bottom: var(--space-sm-breakpoint-xs);
  margin-top: var(--space-sm-breakpoint-xs);
}

@screen sm {
  .my-sm {
    margin-bottom: var(--space-sm-breakpoint-sm);
    margin-top: var(--space-sm-breakpoint-sm);
  }
}

@screen md {
  .my-sm {
    margin-bottom: var(--space-sm-breakpoint-md);
    margin-top: var(--space-sm-breakpoint-md);
  }
}

.my-md {
  margin-bottom: var(--space-md-breakpoint-xs);
  margin-top: var(--space-md-breakpoint-xs);
}

.my-fw {
  margin-bottom: var(--space-fw-breakpoint-xs);
  margin-top: var(--space-fw-breakpoint-xs);
}


@screen sm {
  .my-md {
    margin-bottom: var(--space-md-breakpoint-sm);
    margin-top: var(--space-md-breakpoint-sm);
  }
}

@screen sm {
  .my-fw {
    margin-bottom: var(--space-fw-breakpoint-sm);
    margin-top: var(--space-fw-breakpoint-sm);
  }
}

@screen md {
  .my-md {
    margin-bottom: var(--space-md-breakpoint-md);
    margin-top: var(--space-md-breakpoint-md);
  }
}

@screen md {
  .my-fw {
    margin-bottom: var(--space-fw-breakpoint-md);
    margin-top: var(--space-fw-breakpoint-md);
  }
}

.my-lg {
  margin-bottom: var(--space-lg-breakpoint-xs);
  margin-top: var(--space-lg-breakpoint-xs);
}

@screen sm {
  .my-lg {
    margin-bottom: var(--space-lg-breakpoint-sm);
    margin-top: var(--space-lg-breakpoint-sm);
  }
}

@screen md {
  .my-lg {
    margin-bottom: var(--space-lg-breakpoint-md);
    margin-top: var(--space-lg-breakpoint-md);
  }
}

.my-xl {
  margin-bottom: var(--space-xl-breakpoint-xs);
  margin-top: var(--space-xl-breakpoint-xs);
}

@screen sm {
  .my-xl {
    margin-bottom: var(--space-xl-breakpoint-sm);
    margin-top: var(--space-xl-breakpoint-sm);
  }
}

@screen md {
  .my-xl {
    margin-bottom: var(--space-xl-breakpoint-md);
    margin-top: var(--space-xl-breakpoint-md);
  }
}

.mx-sm {
  margin-right: var(--space-sm-breakpoint-xs);
  margin-left: var(--space-sm-breakpoint-xs);
}

@screen sm {
  .mx-sm {
    margin-right: var(--space-sm-breakpoint-sm);
    margin-left: var(--space-sm-breakpoint-sm);
  }
}

@screen md {
  .mx-sm {
    margin-right: var(--space-sm-breakpoint-md);
    margin-left: var(--space-sm-breakpoint-md);
  }
}

.pb-sm {
  padding-bottom: var(--space-sm-breakpoint-xs);
}

@screen sm {
  .pb-sm {
    padding-bottom: var(--space-sm-breakpoint-sm);
  }
}

@screen md {
  .pb-sm {
    padding-bottom: var(--space-sm-breakpoint-md);
  }
}

.pb-md {
  padding-bottom: var(--space-md-breakpoint-xs);
}

@screen sm {
  .pb-md {
    padding-bottom: var(--space-md-breakpoint-sm);
  }
}

@screen md {
  .pb-md {
    padding-bottom: var(--space-md-breakpoint-md);
  }
}

.pt-sm {
  padding-top: var(--space-sm-breakpoint-xs);
}

@screen sm {
  .pt-sm {
    padding-top: var(--space-sm-breakpoint-sm);
  }
}

@screen md {
  .pt-sm {
    padding-top: var(--space-sm-breakpoint-md);
  }
}

.pt-md {
  padding-top: var(--space-md-breakpoint-xs);
}

@screen sm {
  .pt-md {
    padding-top: var(--space-md-breakpoint-sm);
  }
}

@screen md {
  .pt-md {
    padding-top: var(--space-md-breakpoint-md);
  }
}

.py-xs {
  padding-bottom: var(--space-xs-breakpoint-xs);
  padding-top: var(--space-xs-breakpoint-xs);
}

@screen sm {
  .py-xs {
    padding-bottom: var(--space-xs-breakpoint-sm);
    padding-top: var(--space-xs-breakpoint-sm);
  }
}

@screen md {
  .py-xs {
    padding-bottom: var(--space-xs-breakpoint-md);
    padding-top: var(--space-xs-breakpoint-md);
  }
}

.py-sm {
  padding-bottom: var(--space-sm-breakpoint-xs);
  padding-top: var(--space-sm-breakpoint-xs);
}

@screen sm {
  .py-sm {
    padding-bottom: var(--space-sm-breakpoint-sm);
    padding-top: var(--space-sm-breakpoint-sm);
  }
}

@screen md {
  .py-sm {
    padding-bottom: var(--space-sm-breakpoint-md);
    padding-top: var(--space-sm-breakpoint-md);
  }
}

.py-md {
  padding-bottom: var(--space-md-breakpoint-xs);
  padding-top: var(--space-md-breakpoint-xs);
}

@screen sm {
  .py-md {
    padding-bottom: var(--space-md-breakpoint-sm);
    padding-top: var(--space-md-breakpoint-sm);
  }
}

@screen md {
  .py-md {
    padding-bottom: var(--space-md-breakpoint-md);
    padding-top: var(--space-md-breakpoint-md);
  }
}

.py-lg {
  padding-bottom: var(--space-lg-breakpoint-xs);
  padding-top: var(--space-lg-breakpoint-xs);
}

@screen sm {
  .py-lg {
    padding-bottom: var(--space-lg-breakpoint-sm);
    padding-top: var(--space-lg-breakpoint-sm);
  }
}

@screen md {
  .py-lg {
    padding-bottom: var(--space-lg-breakpoint-md);
    padding-top: var(--space-lg-breakpoint-md);
  }
}

.px-md {
  padding-right: var(--space-md-breakpoint-xs);
  padding-left: var(--space-md-breakpoint-xs);
}

@screen sm {
  .px-md {
    padding-right: var(--space-md-breakpoint-sm);
    padding-left: var(--space-md-breakpoint-sm);
  }
}

@screen md {
  .px-md {
    padding-right: var(--space-md-breakpoint-md);
    padding-left: var(--space-md-breakpoint-md);
  }
}

.space-y-sm > * + * {
  --tw-space-y-reverse: 0;
  margin-top: calc(var(--space-sm-breakpoint-xs) * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(var(--space-sm-breakpoint-xs) * var(--tw-space-y-reverse));
}

@screen sm {
  .space-y-sm > * + * {
    margin-top: calc(var(--space-sm-breakpoint-sm) * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(var(--space-sm-breakpoint-sm) * var(--tw-space-y-reverse));
  }
}

@screen md {
  .space-y-sm > * + * {
    margin-top: calc(var(--space-sm-breakpoint-md) * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(var(--space-sm-breakpoint-md) * var(--tw-space-y-reverse));
  }
}

.space-y-xs > * + * {
  --tw-space-y-reverse: 0;
  margin-top: calc(var(--space-xs-breakpoint-xs) * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(var(--space-xs-breakpoint-xs) * var(--tw-space-y-reverse));
}

@screen sm {
  .space-y-xs > * + * {
    margin-top: calc(var(--space-xs-breakpoint-sm) * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(var(--space-xs-breakpoint-sm) * var(--tw-space-y-reverse));
  }
}

@screen md {
  .space-y-xs > * + * {
    margin-top: calc(var(--space-xs-breakpoint-md) * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(var(--space-xs-breakpoint-md) * var(--tw-space-y-reverse));
  }
}

.space-y-md > * + * {
  --tw-space-y-reverse: 0;
  margin-top: calc(var(--space-md-breakpoint-xs) * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(var(--space-md-breakpoint-xs) * var(--tw-space-y-reverse));
}

@screen sm {
  .space-y-md > * + * {
    margin-top: calc(var(--space-md-breakpoint-sm) * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(var(--space-md-breakpoint-sm) * var(--tw-space-y-reverse));
  }
}

@screen md {
  .space-y-md > * + * {
    margin-top: calc(var(--space-md-breakpoint-md) * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(var(--space-md-breakpoint-md) * var(--tw-space-y-reverse));
  }
}
