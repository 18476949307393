/* BODY TEXT */
.body-text-xs {
  @apply font-sans;
  font-size: 0.6875rem;
  line-height: 1.45;
}

@screen xl {
  .body-text-xs:not(.constantSize) {
    font-size: 0.75rem;
  }
}

.body-text-sm {
  @apply font-sans;
  font-size: 0.8125rem;
  line-height: 1.45;
}

@screen xl {
  .body-text-sm:not(.constantSize) {
    font-size: 0.875rem;
  }
}

.body-text-md {
  @apply font-sans;
  font-size: 1rem;
  line-height: 1.45;
}

@screen xl {
  .body-text-md:not(.constantSize) {
    font-size: 1.125rem;
  }
}

.body-text-lg {
  @apply font-sans;
  font-size: 1.125rem;
  line-height: 1.4;
}

@screen sm {
  .body-text-lg:not(.constantSize) {
    font-size: 1.25rem;
  }
}

@screen xl {
  .body-text-lg:not(.constantSize) {
    font-size: 1.375rem;
  }
}

/* HEADINGS */
.heading-text-xxxs {
  @apply font-semibold;
  font-size: 0.8125rem;
  line-height: 1.45;
  letter-spacing: -0.01em;
}

.heading-text-xxs {
  @apply font-semibold;
  font-size: 0.875rem;
  line-height: 1.4;
  letter-spacing: -0.01em;
}

@screen md {
  .heading-text-xxs:not(.constantSize) {
    font-size: 1rem;
  }
}

.heading-text-xs {
  @apply font-semibold;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: -0.01em;
}

@screen sm {
  .heading-text-xs {
    font-size: 1.25rem;
  }
}

@screen lg {
  .heading-text-xs {
    font-size: 1.375rem;
  }
}

.heading-text-sm {
  @apply font-semibold;
  font-size: 1.25rem;
  line-height: 1.35;
  letter-spacing: -0.02em;
}

@screen sm {
  .heading-text-sm {
    font-size: 1.5rem;
  }
}

@screen md {
  .heading-text-sm {
    font-size: 1.625rem;
  }
}

@screen xl {
  .heading-text-sm {
    font-size: 1.75rem;
  }
}

.heading-text-md {
  @apply font-semibold;
  font-size: 1.5rem;
  line-height: 1.35;
  letter-spacing: -0.02em;
}

@screen sm {
  .heading-text-md {
    font-size: 1.875rem;
  }
}

@screen md {
  .heading-text-md {
    font-size: 2.125rem;
  }
}

@screen lg {
  .heading-text-md {
    font-size: 2.25rem;
  }
}

@screen xl {
  .heading-text-md {
    font-size: 2.375rem;
  }
}

.heading-text-lg {
  @apply font-semibold;
  font-size: 1.75rem;
  line-height: 1.3;
  letter-spacing: -0.03em;
}

@screen sm {
  .heading-text-lg {
    font-size: 2.25rem;
  }
}

@screen md {
  .heading-text-lg {
    font-size: 2.5rem;
  }
}

@screen lg {
  .heading-text-lg {
    font-size: 2.625rem;
  }
}

@screen xl {
  .heading-text-lg {
    font-size: 2.875rem;
  }
}

.heading-text-xl {
  @apply font-semibold;
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: -0.03em;
}

@screen sm {
  .heading-text-xl {
    font-size: 2.5rem;
  }
}

@screen md {
  .heading-text-xl {
    font-size: 3rem;
  }
}

@screen lg {
  .heading-text-xl {
    font-size: 3.25rem;
  }
}

@screen xl {
  .heading-text-xl {
    font-size: 3.875rem;
  }
}

.heading-text-xxl {
  @apply font-semibold;
  font-size: 2.625rem;
  line-height: 1.25;
  letter-spacing: -0.03em;
}

@screen sm {
  .heading-text-xxl {
    font-size: 3.75rem;
  }
}

@screen md {
  .heading-text-xxl {
    font-size: 4.375rem;
  }
}

@screen lg {
  .heading-text-xxl {
    font-size: 4.875rem;
  }
}

@screen xl {
  .heading-text-xxl {
    font-size: 5.5rem;
  }
}

.text-new-flag {
  @apply font-sans;
  font-size: 0.5rem;
  font-weight: 700;
  line-height: 1.95;
}
