:root {
  --gw-gap: 0.75rem;
  --gw-margin: 3rem;
  --gw-columns: 4;
  --gw-container-width: 100vw;
}

@screen sm {
  :root {
    --gw-gap: 1rem;
    --gw-margin: 5.5rem;
    --gw-columns: 12;
  }
}

@screen md {
  :root {
    --gw-margin: 7rem;
  }
}

@screen lg {
  :root {
    --gw-margin: 8rem;
  }
}

@screen xl {
  :root {
    --gw-container-width: 1680px;
  }
}

@layer components {
  .w-col-1,
  .w-col-2,
  .w-col-3,
  .w-col-4,
  .w-col-5,
  .w-col-6,
  .w-col-7,
  .w-col-8,
  .w-col-9,
  .w-col-10,
  .w-col-11,
  .w-col-12 {
    width: calc(
      (
          (
              (
                  var(--gw-container-width) - var(--gw-margin) -
                    ((var(--gw-columns) - 1) * var(--gw-gap))
                ) / var(--gw-columns)
            ) * var(--gw-colWidth)
        ) + ((var(--gw-colWidth) - 1) * var(--gw-gap))
    );
  }

  .min-w-col-1,
  .min-w-col-2,
  .min-w-col-3,
  .min-w-col-4,
  .min-w-col-5,
  .min-w-col-6,
  .min-w-col-7,
  .min-w-col-8,
  .min-w-col-9,
  .min-w-col-10,
  .min-w-col-11,
  .min-w-col-12 {
    min-width: calc(
      (
          (
              (
                  var(--gw-container-width) - var(--gw-margin) -
                    ((var(--gw-columns) - 1) * var(--gw-gap))
                ) / var(--gw-columns)
            ) * var(--gw-colWidth)
        ) + ((var(--gw-colWidth) - 1) * var(--gw-gap))
    );
  }

  .w-col-1 {
    --gw-colWidth: 1;
  }
  .min-w-col-1 {
    --gw-colWidth: 1;
  }

  .w-col-2 {
    --gw-colWidth: 2;
  }
  .min-w-col-2 {
    --gw-colWidth: 2;
  }

  .w-col-3 {
    --gw-colWidth: 3;
  }
  .min-w-col-3 {
    --gw-colWidth: 3;
  }

  .w-col-4 {
    --gw-colWidth: 4;
  }
  .min-w-col-4 {
    --gw-colWidth: 4;
  }

  .w-col-5 {
    --gw-colWidth: 5;
  }
  .min-w-col-5 {
    --gw-colWidth: 5;
  }

  .w-col-6 {
    --gw-colWidth: 6;
  }
  .min-w-col-6 {
    --gw-colWidth: 6;
  }

  .w-col-7 {
    --gw-colWidth: 7;
  }
  .min-w-col-7 {
    --gw-colWidth: 7;
  }

  .w-col-8 {
    --gw-colWidth: 8;
  }
  .min-w-col-8 {
    --gw-colWidth: 8;
  }

  .w-col-9 {
    --gw-colWidth: 9;
  }
  .min-w-col-9 {
    --gw-colWidth: 9;
  }

  .w-col-10 {
    --gw-colWidth: 10;
  }
  .min-w-col-10 {
    --gw-colWidth: 10;
  }

  .w-col-11 {
    --gw-colWidth: 11;
  }
  .min-w-col-11 {
    --gw-colWidth: 11;
  }

  .w-col-12 {
    --gw-colWidth: 12;
  }
  .min-w-col-12 {
    --gw-colWidth: 12;
  }
}
